var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { getSpacingSelector, getColorPrimarySelector, getColorBackgroundSelector } from '@signal/theme/selectors';
import Skeleton from '../Skeleton/Typography';
import Typography from '../Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: ", "px ", "px 0px\n    ", "px;\n"])), getSpacingSelector(2), getSpacingSelector(1), getSpacingSelector(1));
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'body1',
    color: 'primaryDark'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 0;\n"])));
export var TitleSkeleton = styled(Title).attrs(function () {
  return {
    as: Skeleton,
    width: 180,
    color: 'skeletonDark'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Recents = styled(List)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n"])));
export var RecentAccount = styled(ListItem).attrs(function () {
  return {
    variant: 'body3'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: ", "px 0px;\n  cursor: pointer;\n  &:hover span {\n    color: ", ";\n    background-color: ", ";\n  }\n"])), getSpacingSelector(0.5), getColorPrimarySelector(), getColorBackgroundSelector('paper'));
export var DefaultMessage = styled(Typography)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding: ", "px 0px;\n"])), getSpacingSelector(0.5));
export var RecentAccountSkeleton = styled(Skeleton).attrs(function () {
  return {
    width: 220
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral([""])));