import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import useDarkMode from 'use-dark-mode'
import { createGlobalStyle } from 'styled-components'
import createPersistedState from '@signal/core-hooks/usePersistedState'
import { activeAccountIdSelector } from '@signal/core/auth'
import { Provider as SignalProvider } from '@signal/core/signal'
import { Router } from '@signal/router'
import injectSaga, { SAGA_MODES } from '@signal/hoc/injectSaga'
import { listen as apiListen } from '@signal/core/api'
import TenantThemeLoader from '@signal/core/tenantTheme/Loader'
import { createGlobalStyles } from '@signal/layouts/globalStyles'
import initFullStory from '@signal/utils/fullStory'
import Suspense from '@signal/utils/Suspense'
// import OnboardingTour from './containers/OnboardingTour'
// TODO: remove this crutch loader once all user/account data is fetched using fetch context
import ReduxCrutchLoader from './containers/ReduxCrutchLoader'
import { getMockTheme } from './ab-tests'
import { redirectToLatestDomain } from './redirect'
import routerConfig from './routes'
// import { darkTheme, lightTheme } from './theme'
import { lightTheme } from './theme'
import './styles/globals.css'

const AppProvider = injectSaga({
  key: 'api',
  saga: apiListen,
  mode: SAGA_MODES.DAEMON
})(SignalProvider)

const SignalLayoutStyles = createGlobalStyles('app-root')

export const GlobalFontSize = createGlobalStyle`
  html {
    font-size: ${props =>
      props.value ? `${props.value}%` : '100%'} !important;
  }
`

const useGlobalFontSize = createPersistedState('globalFontSize')

/**
 * This function will override the app min width, which is globally defined in CSS
 * Changing this can improve functionality on low screen resolution systems
 * This function is intended to be called from useEffect
 * @param {String} newAppMinWidth - a string in CSS size units (like "1024px")
 * @return {Function} - a function to return to useEffect() to restore default min width
 */
export const changeAppMinWidthOnMount = newAppMinWidthWidth => {
  const appRoot = document.querySelector('#app-root')
  appRoot.style.minWidth = newAppMinWidthWidth
  return () => {
    appRoot.style.minWidth = '1024px'
  }
}

export default function App() {
  // redirect to latest domain and init FullStory on mount
  useEffect(() => {
    if (!redirectToLatestDomain()) {
      initFullStory()
    }
  }, [])

  const activeAccount = useSelector(activeAccountIdSelector)
  const [globalFontSize] = useGlobalFontSize(100)

  // const darkMode = useDarkMode(false)
  // const [theme, setTheme] = useState(lightTheme)
  // useEffect(() => {
  //   requestAnimationFrame(() => {
  //     setTheme(darkMode.value ? darkTheme : lightTheme)
  //   })
  // }, [darkMode.value])
  // const toastContext = useMemo(() => ({ showToast }), [showToast])
  return (
    <Suspense>
      <TenantThemeLoader mockTheme={getMockTheme()}>
        <AppProvider accountId={activeAccount} theme={lightTheme}>
          {/* <Context.Provider value={toastContext}> */}
          {/* <AppProvider theme={theme}> */}
          <GlobalFontSize value={globalFontSize} />
          {/* Removed Onboarding for NADA, should update with Meridian stuff before putting back in */}
          {/* <OnboardingTour /> */}
          <SignalLayoutStyles />
          <ReduxCrutchLoader />
          <Router config={routerConfig} activeAccount={activeAccount} />
        </AppProvider>
      </TenantThemeLoader>
    </Suspense>
  )
}
