import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@signal/components/Typography'
import {
  getSpacingSelector,
  getColorPrimarySelector,
  getColorBackgroundSelector
} from '@signal/theme/selectors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const Actions = styled(List)`
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const Action = styled(ListItem)`
  padding: 0px ${getSpacingSelector(1)}px ${getSpacingSelector(1)}px
    ${getSpacingSelector(1)}px;
  margin-bottom: ${getSpacingSelector(1)}px;
  cursor: pointer;
  &:hover span {
    color: ${getColorPrimarySelector()};
    background-color: ${getColorBackgroundSelector('paper')};
  }
`

export const StyledText = styled(Typography).attrs(() => ({
  variant: 'body1',
  color: 'primary'
}))`
  font-weight: 400;
  cursor: pointer;
`
