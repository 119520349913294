var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MuiAvatar from '@material-ui/core/Avatar';
import { getColorPrimarySelector, getPxToRemSelector, getSpacingSelector } from '@signal/theme/selectors';
import Skeleton from '../Skeleton/Typography';
import Typography from '../Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: ", "px;\n"])), getSpacingSelector(1));
export var Avatar = styled(MuiAvatar)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 60px;\n  height: 60px;\n  font-size: ", ";\n  background-color: ", ";\n"])), getPxToRemSelector(20), getColorPrimarySelector());
export var Details = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-self: flex-start;\n  margin-top: ", "px;\n\n  & > *:not(:last-of-type) {\n    margin-bottom: ", "px;\n  }\n"])), getSpacingSelector(2), getSpacingSelector(0.5));
export var PrimaryDetail = styled(Typography).attrs(function () {
  return {
    variant: 'body1',
    color: 'primaryDark'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var PrimaryDetailSkeleton = styled(PrimaryDetail).attrs(function () {
  return {
    as: Skeleton,
    width: 180,
    color: 'skeletonDark'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var SecondaryDetail = styled(Typography).attrs(function () {
  return {
    variant: 'body2'
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-bottom: ", "px;\n"])), getSpacingSelector(1));
export var SecondaryDetailSkeleton = styled(SecondaryDetail).attrs(function () {
  return {
    as: Skeleton,
    width: 140
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral([""])));