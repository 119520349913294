import React from 'react'
import { useSelector } from 'react-redux'
import Link from '@signal/components/Link'
import { actionsSelector } from './selectors'
import { Wrapper, Actions, Action, StyledText } from './styles'

export function UserMenuNavLinks({ onClickLink }) {
  const actions = useSelector(actionsSelector)

  return (
    <Wrapper>
      <Actions>
        {actions.map(({ id, label, to }) => (
          <Action component={Link} to={to} key={to} onClick={onClickLink}>
            <StyledText>{label}</StyledText>
          </Action>
        ))}
      </Actions>
    </Wrapper>
  )
}

UserMenuNavLinks.defaultProps = {
  onClickLink: () => null
}

export default UserMenuNavLinks
